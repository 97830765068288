<template>
  <div class="znzz">
    <div class="header">
      <div class="zhuti">
        <div class="h1">全景产业链地图</div>
        <div class="h2">
          <p style="width: 413px">通过优化供应链管理、增强协同效应、提高市场响应速度等多方面，为企业带来了显著的竞争优势，是现代企业提升运营效率、实现可持续发展的关键工具。</p>
        </div>
        <div style="margin-top: 30px">
          <el-button type="primary" @click="goadmin(datas.topUrl)">立即体验</el-button>
        </div>
      </div>
    </div>
    <div class="titles pad56" id="m1">平台优势</div>
    <div class="cj">
      <div class="zhuti" style="padding-left: 198px">
        <div class="cjlist flex"  v-for="(li,index) in advantageList" :key="index">
          <div class="img"><el-image :src="li.icon"></el-image></div>
          <div class="mag32 flex-1">
            <div class="name">{{li.name}}</div>
            <div class="desc">{{li.titleSub}}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 1000px;margin-bottom: 56px">
      <el-image :src="datas.sceneImg"></el-image>
    </div>
    <div class="titles" id="m2">平台板块</div>
    <div class="zhuti" style="margin-bottom: 56px">
      <div class="tabs flex">
        <div class="tablist flex-1" @click="tabchoose(li,index)" v-for="(li,index) in plateList" :key="index"><span :class="{'isactive':number==index}">{{li.name}}</span></div>
      </div>
      <div class="flex bkcontent">
        <div class="image"><el-image :src="plateList[number].image"></el-image></div>
        <div class=" flex-1 right">
          <div class="h1">{{plateList[number].name}}</div>
          <div style="color: #666666;">{{plateList[number].content}}</div>
          <el-divider></el-divider>
          <div>
            <el-button type="primary" @click="goadmin(plateList[number].link)">查看更多</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {equipmentmanagementindex} from "../../request/moudle/znzz";
import Advice from "../common/advice.vue";
import MoreList from "../common/moreList.vue";
import config from '../../utils/config'
export default {
  name: "",
  components:{
    Advice,
    MoreList
  },
  data(){
    return  {
      number:0,
      datas:'',
      advantageList:[],
      plateList:[]
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    scrollToAnchor(anchor) {
      this.$nextTick(() => {
        const anchorElement = document.getElementById(anchor);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    tabchoose(li,index){
      this.number=index
      this.scrollToAnchor(li.id)
    },
    loadconfig(){
      this.$api.znzz.mapindex().then((res)=>{
        this.datas=res.data
        this.advantageList=res.data.advantageList
        this.plateList=res.data.plateList
      })
    },
    goadmin(src){
      window.open(config.adminhref+`#${src}`,'_blank')
    },
    remove(e){
      let hdimage=this.$refs.hdimage
      for (const val of hdimage){
        val.className='hdimage display'
      }
      e.target.className='hdimage remove-class show'
    },

  }
}
</script>

<style lang="scss" scoped>
.znzz{
  background: #fff;
}
.mag32{
  margin: 41px 10px 0 10px;
}
.header{
  height: 800px;
  background: url("../../assets/banner/map.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  color: #000;
  .h1{
    font-size: 42px;
    margin-top: 275px;
    font-weight: bolder;
  }
  .h2{
    font-size: 18px;
    line-height: 30px;
    margin-top: 25px;
    color: #666666;
  }
}
.bkcontent{
  .image{
    width: 640px;
    height: 401px;
    margin: 15px;
  }
  .h1{
      font-size: 18px;
    font-weight: bolder;
    margin-bottom: 20px;
  }
  .right{
    width: 380px;
    margin: 20px;
    margin-top: 80px;
  }
}
.cj{
  overflow: hidden;
  margin-bottom: 56px;
  .cjlist{
    width: 300px;
    height: 160px;
    float: left;
    position: relative;
    margin: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    .img{
      width: 100px;
      height: 100px;
      margin: 30px 20px;
    }
  }
  .name{
    font-size: 18px;
    font-weight: bolder;
    color: #1D1D1D;
    margin-bottom: 12px;
  }
  .desc{
    color: #999;
    width: 115px;
  }
}
.tabs{
  overflow: hidden;
  border-bottom: 1px solid #eee;
  .tablist{
    text-align: center;
    cursor: pointer;
  }
  span{
    display: inline-block;
    padding: 15px;
  }
  .isactive{
    border-bottom: 2px solid #0c8aff;
  }
}
.zhuti{
  width: 1200px;
  margin: 0px auto;
  overflow: hidden;
}
.titles{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  padding-bottom: 56px;
  color: #1D1D1D;
}
.pad56{
  padding-top: 56px;
}
</style>